import { defineRouting } from "next-intl/routing";
import { createSharedPathnamesNavigation } from "next-intl/navigation";

export const locales = ["en", "da"] as const;
export const defaultLocale = "da";

export type Locale = (typeof locales)[number];

export const routing = defineRouting({
    locales,
    defaultLocale,
});

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation(routing);
