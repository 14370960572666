"use client";

import { FC, useEffect } from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@ui/dropdown-menu";
import { Button } from "@ui/button";
import { LOCALE_STORAGE_KEY } from "~/constants/general.constants";
import { cn } from "~/utils/lib.utils";
import { Locale, locales, usePathname, useRouter } from "~/i18n/routing";
import { useLocale, useTranslations } from "next-intl";

interface LanguageSelectorProps {
    className?: string;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ className = "" }) => {
    const currentLocale = useLocale();
    const t = useTranslations();
    const { replace, refresh } = useRouter();
    const pathname = usePathname();

    const replaceRoute = (locale: Locale) => {
        replace(pathname, { locale });
        refresh();
    };

    useEffect(() => {
        const storedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);

        if (storedLocale) {
            replaceRoute(storedLocale as Locale);
        }
    }, []);

    const handleLocaleClick = (locale: Locale) => {
        localStorage.setItem(LOCALE_STORAGE_KEY, locale || "");
        replaceRoute(locale);
    };

    return (
        <div className={className}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <div className="relative">
                        <Button variant="secondary" size="sm" className="font-bold uppercase">
                            {currentLocale}
                        </Button>
                    </div>
                </DropdownMenuTrigger>

                <DropdownMenuContent align="end">
                    {locales?.map((locale) => (
                        <DropdownMenuItem
                            className={cn(
                                "cursor-pointer",
                                locale === currentLocale && "bg-primary text-white focus:bg-primary focus:text-white",
                            )}
                            onClick={() => handleLocaleClick(locale)}
                            key={locale}
                        >
                            {t(`locales.${locale as Locale}`)}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
};
